<template>
  <div class="sourcery__grid">
    <CollectionsSearchItem
      v-for="item in items"
      :id="item.id"
      :key="item.id"
      v-intersect.once="onIntersect"
      :is-show-action-icon="isShowActionIcon"
      :data-test="`${testValue}_block`"
      :test-value="testValue"
      :item="item"
      :criteria="criteria"
      class="items main-items"
      @click-action-menu="$emit('click-action-menu', $event)"
      @click.native="$emit('handleItemClick', item)" />

    <!-- loading -->
    <template v-if="isLoadingItems">
      <AppCardSkeleton
        v-for="index in 4"
        :key="index" />
    </template>
  </div>
</template>
<script>
import AppCardSkeleton from '@/components/App/AppCardSkeleton';
import CollectionsSearchItem from './CollectionsSearchItem';
import ToggleViewCondition from '@/mixins/ToggleViewCondition';
export default {
  name: 'CollectionsSearchCollapsedRows',
  components: {
    AppCardSkeleton,
    CollectionsSearchItem,
  },
  mixins: [ToggleViewCondition],
  props: {
    criteria: {
      type: String,
      default: '',
    },
    isShowActionIcon: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    testValue: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    isLoadingItems: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isListingView() {
      return this.toggleViewCondition('librariesRelatedPages');
    },
  },
  methods: {
    onIntersect(entries, observer, isIntersecting) {
      this.$emit('onIntersect', {
        entries, observer, isIntersecting,
      });
    },
  },
};
</script>
<style lang="scss">
</style>
